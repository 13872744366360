import uniqid from "uniqid"
import { competences } from "../../portfolio"
import IUTSpecificSkills from "../IUTSpecificSkill/IUTSpecificSkill"
import "./IUTSkills.css";

function IUTSkills(){
    if(!competences.length) return null

    return (
        <section className="section" id="iutskills">
            <h2 className="section__title">Compétences du BUT</h2>
            <section className="carousel">
                <ul className="carousel-items">
                    {competences.map((competence) => (
                        <IUTSpecificSkills key={uniqid()} skill={competence} />
                    ))}
                </ul>
            </section>
        </section>
    )
}

export default IUTSkills