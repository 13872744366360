const header = {
  homepage: "https://portfolio.roguire.fr",
  title: "Romaing",
}

const about = {
  name: "Romain Guion",
  role: "Etudiant en Informatique",
  description:
    "Jeune étudiant en informatique, je suis motivé et curieux. Actuellement en 2e année de BUT, je cherche un stage d'environ 10 semaines à partir du 11 avril. J'aime apprendre et j'adore m'améliorer pour ne pas rester sur des échecs.",
  resume: "https://www.canva.com/design/DAFMBgY68vU/lyqqqofYu34guDcB7YlbbQ/view?utm_content=DAFMBgY68vU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
  social: {
    linkedin: "https://www.linkedin.com/in/romain-guion-57083a222/",
    github: "https://github.com/Roguire14",
  },
}

const projects = [
  {
    name: "Présentation BUT",
    description: "Projet universitaire: SAE du S1. Site web dans lequel on devait faire une présentation de la première année de la formation du BUT Informatique, nous avions évoqué la vie sociale, étudiante et évidemment des informations sur la formation.",
    stack: ["HTML", "CSS"]
  },
  {
    name: "Gestionnaire de contacts",
    description:
      "Projet universitaire: SAE du S1. Application en ligne de commande permettant d'ajouter/supprimer/modifier/voir les données des personnes données en entrées par le fichier CSV.",
    stack: ["C", "CSV"],
    sourceCode: "https://github.com/Roguire14/sae-c-private",
  },
  {
    name: "Poster Robot Compagnon",
    description: "Projet universitaire: SAE du S2. Il s'agit d'un poster au format A3 sur les robots compagnons où on réfléchit sur le fait qu'ils sont conçus pour accompagner mais qu'ils peuvent manipuler.",
    stack: ["Design", "Figma"],
    livePreview: "https://drive.google.com/file/d/1e3fRUIfT9sJY6wjncYQ1RB306Zxrnats/view?usp=sharing"
  },
  {
    name: "Analyse d'un graphe-map",
    description:
      "Projet universitaire: SAE du S2. Application avec interface graphique permettant de visualiser un graphe-map à partir d'un fichier CSV ou JSON respectant un certain format, de visualiser les accès à 0,1,2,n distances d'un sommet, de limiter l'affichage à certaines arrêtes.",
    stack: ["Java", "Swing", "JSON", "CSV"],
    sourceCode: "https://github.com/Roguire14/SAE_GRAMA",
  },
//  {
//    name: "Création d'une base de donnée",
//    description: "Projet universitaire: SAE du S2. Il s'agit d'un projet où il fallait créer une base de données en utilisant PowerAMC. Il fallait créer une D "
//  },
  {
    name: "Projet PCTech",
    description: "Projet universitaire: SAE du S3. Il s'agit d'un site web d'e-commerce de composant informatique mettant en place toutes les features qu'on pourrait retrouver sur un site officiel, notamment un panel administrateur permmettant d'ajouter, modifier et supprimer des articles et voir les différents comptes et les commandes passées.",
    stack: ["HTML","CSS","JS","PHP","Figma"],
    livePreview: "https://pctech.alwaysdata.net"
  },
  {
    name: "Bot Baguette",
    description:
      "Premier projet avec l'API Discord Py, c'était un bot multi-fonctions (administrations, musiques, gestion d'accès sur serveur Minecraft, ...). Il permettait notamment d'assigner un rôle à l'entrée d'un joueur après qu'il ait entré son pseudo, il gérait les maps pour les modes de jeux Minecraft présent sur le serveur.",
    stack: ["Python", "JSON"],
  },
  {
    name: "Scénarios UHC Minecraft",
    description: "Début des projets avec l'API Spigot qui permet d'ajouter du contenu à Minecraft. Ces scénarios m'ont permis de manipuler l'API avant de faire un gros projet.",
    stack: ["Java", "Spigot"],
    sourceCode: "https://github.com/Roguire14/Minecraft-UHC-Scenarios",
  },
  {
    name: "Dragon Rush UHC",
    description: "Premier gros projet avec l'API Spigot. C'est mon premier mode de jeu UHC sur Minecraft. Il m'a permis de manipuler en profondeur Spigot. Il reprend le concept du speedrun mais l'adpate au format UHC",
    stack: ["Java", "Spigot"],
  },
  {
    name: "Didier",
    description:
      "Deuxième projet avec l'API Discord Py, ajout de Tweepy (bibliothèque pour utiliser Twitter avec Python), prévu prendre la suite du serveur du Baguette avec de plus grandes capacités. Projet abandonnée à cause d'une perte d'accès à la fibre.",
    stack: ["Python", "JSON"],
    sourceCode: "https://github.com/Roguire14/discord-bot-perso"
  },
  {
    name: "TCLGuesser",
    description: "Projet universitaire: projet du S4. Il s'agit d'un jeux sur mobile sur Android reprenant le concept de GeoGuessr mais adapté à un plan 2D et sur les arrêts du réseau TCL. Dispose d'un classement général all-time",
    stack: ["Kotlin", "Firebase", "XML"]
  }
]

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "PHP",
  "Symfony",
  "Python",
  "Java",
  "Git",
  "SQL",
  "PL/SQL",
  "Kotlin",
  "JSON",
  "XML",
  "Figma",
  "Firebase"
]

const competences = [
  {
    name: "Compétence 1",
    full_name: "Réaliser une application",
    projets: [
      "Gestionnaire de contacts",
      "Analyse d'un graphe-map",
      "Projet PCTech"
    ]
  },
  {
    name: "Compétence 2",
    full_name: "Optimiser une application",
    projets: [
      "Gestionnaire de contacts",
      "Projet PCTech"
    ]
  },
  {
    name: "Compétence 3",
    full_name: "Administrer un système",
    projets: [
      "Projet PCTech"
    ]
  },
  {
    name: "Compétence 4",
    full_name: "Gérer des données",
    projets: [
      "Projet PCTech"
    ]
  },
  {
    name: "Compétence 5",
    full_name: "Conduire un projet",
    projets: [
      "Projet PCTech"
    ]
  },
  {
    name: "Compétence 6",
    full_name: "Collaborer en équipe",
    projets: [
      "Présentation BUT",
      "Projet PCTech"
    ]
  }
]

const contact = {
  email: "romain.guion@aol.com",
}

export { header, about, projects, skills, contact, competences }
