import uniqid from 'uniqid';
import "./IUTSpecificSkill.css"

const IUTSpecificSkill = ({ skill }) => (
    <li className="carousel-item">
        <div>
            <h3>{skill.name}</h3>
            <h4>{skill.full_name}</h4>
            <ul>
                {skill.projets.map((projet) => (
                    <li key={uniqid()}>
                        <a href={`#${projet}`}>{projet}</a>
                    </li>
                ))}
            </ul>
        </div>
    </li>
)

export default IUTSpecificSkill
